import React, {useContext} from 'react';
import * as styles from './LeadRequestSubmissionResult.module.css';
import WhiteBox from '../common/WhiteBox';
import {ReactComponent as CheckCircleIcon} from '../../assets/images/check-circle-icon.svg';
import LargeButton from '../common/LargeButton';
import {Link} from 'react-router-dom';
import UserContext from '../../context/UserContext';
import {UserRoles} from '../../utils/UserUtils';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const LeadRequestSubmissionResult = ({submittedLead, finishButtonCallback}) => {
    const {user} = useContext(UserContext);
    return (
        <div className={styles.wrapper}>
            <WhiteBox>
                <div className={styles.titleContainer}>
                    <CheckCircleIcon/>
                    <div className={styles.titleText}>{(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? 'Заявка сохранена' : 'Заявка отправлена'}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowLabel}>Клиент</div>
                    <div className={styles.rowValue}>{submittedLead?.surname} {submittedLead?.name} {submittedLead?.patronymic}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowLabel}>Автомобиль</div>
                    <div className={styles.rowValue}>{submittedLead?.carName}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.rowLabel}>Сумма</div>
                    <div className={styles.rowValue}>{formatNumberWithSpaces(submittedLead?.price)} {submittedLead?.currency}</div>
                </div>
                <div className={styles.buttonsContainer}>
                    <LargeButton filled={false} onClick={finishButtonCallback}>{(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? 'Новый расчёт' : 'Новая заявка'}</LargeButton>
                    <Link to={`/request/${submittedLead.link}`}><LargeButton style={{width: '100%'}}>Перейти к заявке</LargeButton></Link>
                </div>
            </WhiteBox>
        </div>
    );
};

export default LeadRequestSubmissionResult;