import React from 'react';
import * as styles from './MonthlyPaymentListHeader.module.css';

const MonthlyPaymentListHeader = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.cell}>
                № месяца
            </div>
            <div className={styles.cell}>
                Возмещение контрактной стоимости, c&nbsp;НДС
            </div>
            <div className={styles.cell}>
                Лизинговое вознаграждение
            </div>
            <div className={styles.cell}>
                НДС на ЛВ
            </div>
            <div className={styles.cell}>
                Сумма лизингового платежа с&nbsp;НДС
            </div>
        </div>
    );
};

export default MonthlyPaymentListHeader;