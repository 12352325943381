export default function formatNumberWithSpaces(num) {
    if (!num)
        return '';
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum))
        return '';

    const floatingPointPos = parsedNum.toString().indexOf('.');
    if (floatingPointPos !== -1){
        const intPart = parseInt(num);
        return  intPart.toLocaleString('ru-RU') + parsedNum.toFixed(2).slice(floatingPointPos);
    }else{
        return parsedNum.toLocaleString('ru-RU');
    }
}