import React from 'react';
import LoginForm from '../components/form/LoginForm';
import styles from './LoginPage.module.css';
import AuthFormWrapper from '../components/form/AuthFormWrapper';

const LoginPage = () => {
    return (
        <div className={styles.formWrapper}>
            <AuthFormWrapper>
                <LoginForm/>
            </AuthFormWrapper>
        </div>
    );
};

export default LoginPage;