import React, {useContext, useState} from 'react';
import * as styles from './CancelRequestBlock.module.css';
import ActionWithConfirmation from '../common/ActionWithConfirmation';
import {cancelLeadRequest} from '../../api/Lead';
import {isTokenIssue} from '../../utils/ApiUtils';
import UserContext from '../../context/UserContext';
import RequestContext from '../../context/RequestContext';
import moment from 'moment';
import {RequestStatusTypes} from '../../utils/LeadUtils';
const CancelRequestBlock = () => {
    const {request, setRequest} = useContext(RequestContext);
    const {tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const cancelRequest = () => {
        setLoading(true);
        return cancelLeadRequest(request.link).then(request => {
            setRequest(request);
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Не удалось отменить заявку');
            }
        });
    };

    const determineSubtitle = () => {
        if(request.cancellationRequestedAt){
            return  `Запрос на отмену отправлен ${moment(request.cancellationRequestedAt).format('DD.MM.YYYY в HH:mm')}`;
        }else if (request.status !== RequestStatusTypes.REQUEST_SAVED){
            return 'Действие не является актуальным для данной заявки';
        }else{
            return 'Вы можете отправить запрос на отмену заявки';
        }
    };

    const wrappedCancelRequest = async () =>{
        try{
            await cancelRequest();
        }catch (error) {
            setLoading(true);
            await tryRefreshTokensAndRepeatAction(error.message, async ()=>cancelRequest());
        }
    };

    return (
        <div>
            <ActionWithConfirmation title='Отмена заявки'
                subtitle={determineSubtitle()}
                                    actionButtonTitle='Отменить заявку'
                confirmationText='Действие необратимо. Продолжить?'
                loadingText='Отправка запроса...'
                loading={loading}
                disabled={request.cancellationRequestedAt || request.status !== RequestStatusTypes.REQUEST_SAVED}
                actionToExecute={wrappedCancelRequest}
            />
        </div>
    );
};

export default CancelRequestBlock;