import React, {useState, useContext, useEffect} from 'react';
import * as styles from './SessionsPage.module.css';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import TextHeader from '../components/common/TextHeader';
import {getSessions, destroySession} from '../api/User';
import moment from 'moment';
import {ReactComponent as DesktopIcon} from '../assets/images/desktop-icon.svg'
import {ReactComponent as MobileIcon} from '../assets/images/mobile-icon.svg'
import {ReactComponent as UnknownDeviceIcon} from '../assets/images/unknown-device-icon.svg'
import UserContext from '../context/UserContext';
import {isTokenIssue} from '../utils/ApiUtils';
import LargeButton from '../components/common/LargeButton';

const SessionsPage = () => {
    const [sessions, setSessions] = useState([]);
    const {user, saveUser, tryRefreshTokensAndRepeatAction} = useContext(UserContext);

    const getUserSessions = () => {
        return getSessions().then(sessions => {
            setSessions(sessions);
        }).catch(error =>{
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Не получить список сессий');
            }
        });
    };

    const wrappedGetUserSessions = async () =>{
        try{
            await getUserSessions();
        }catch (error) {
            tryRefreshTokensAndRepeatAction(error.message, ()=>getUserSessions());
        }
    };

    useEffect(async ()=>{
        wrappedGetUserSessions();
    }, []);

    const destroyUserSession = async (sessionIndex) => {
        return destroySession(sessions[sessionIndex].id).then((sessions) => {
            setSessions(sessions);
        }).catch(error =>{
            if (isTokenIssue(error.message))
                throw error;
            else{
                alert('Не удалось завершить сессию');
            }
        });
    };

    const wrappedDestroyUserSession = async (sessionIndex)=> {
        try{
            await destroyUserSession(sessionIndex);
        }catch (error) {
            tryRefreshTokensAndRepeatAction(error.message, () => destroyUserSession(sessionIndex));
        }
    };

    return (
        <div>
            <AppHeader/>
            <PageWrapper>
                <TextHeader>Активные сессии {sessions?.length ? `(${sessions.length})` : 0}</TextHeader>
                {sessions.map((s, key) => <div key={key}>
                    <div className={`${styles.sessionWrapper} ${s.refreshToken === user.refreshToken ? styles.current : ''}`}>
                        <div className={styles.deviceInfo}>
                            <div className={styles.iconWrapper}>
                                {s.deviceType === 'desktop' ? <DesktopIcon/> : ''}
                                {s.deviceType === 'mobile' ? <MobileIcon/> : ''}
                                {s.deviceType !== 'mobile' && s.deviceType !== 'desktop' ? <UnknownDeviceIcon/> : ''}
                            </div>
                            <div>
                                <div className={styles.deviceName}>{s.deviceVendor} {s.deviceModel}</div>
                                <div className={styles.osName}>{s.osName} {s.osVersion}</div>
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}>IP-адрес:</div>
                                    <div className={styles.infoValue}>{s.ip}</div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}>Браузер:</div>
                                    <div className={styles.infoValue}>{s.browserName} {s.browserVersion}</div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}>Вход:</div>
                                    <div className={styles.infoValue}>{moment(s.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </div>
                                <div className={styles.infoRow}>
                                    <div className={styles.infoLabel}>Активен:</div>
                                    <div className={styles.infoValue}>{moment(s.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.currentSessionText}>Текущая сессия</div>
                        {s.refreshToken !== user.refreshToken ? <LargeButton filled={false} className={styles.destroyButton} onClick={()=> wrappedDestroyUserSession(key)}>Завершить сессию</LargeButton> : ''}
                    </div>
                </div>)}
            </PageWrapper>
        </div>
    );
};

export default SessionsPage;