import React, {useContext} from 'react';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import {BrowserRouter, Link} from 'react-router-dom';
import UserContext from '../context/UserContext';
import * as styles from './HomePage.module.css';
import {UserRoles} from '../utils/UserUtils';
import WhiteBox from '../components/common/WhiteBox';
import MainBanner from '../components/common/MainBanner';

const HomePage = () => {
    const {user} = useContext(UserContext);
    return (
        <div>
            <AppHeader/>
            <PageWrapper>
                <MainBanner/>
                {/*<div className={styles.linksContainer}>*/}
                    {/*{user.activated ?*/}
                        {/*<>*/}
                            {/*<Link to='/create-request' className={styles.homePageLink}>*/}
                                {/*{(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? 'Расчёт платежей' : 'Создать заявку'}*/}
                            {/*</Link>*/}
                            {/*<Link to='/requests' className={styles.homePageLink}>*/}
                                {/*Список заявок*/}
                            {/*</Link>*/}
                            {/*<a href={`${process.env.PUBLIC_URL}/reference.pdf`} className={styles.homePageLink}>*/}
                                {/*Справка*/}
                            {/*</a>*/}
                        {/*</>*/}
                    {/*:''}*/}
                    {/*{!user.activated ?*/}
                        {/*<WhiteBox className={styles.notActivated}>*/}
                            {/*<h2>Доступ ограничен</h2>*/}
                            {/*<p>Спасибо за регистрацию. Вам на электронную почту отправлено письмо для подтверждения регистрации. После этого пожалуйста дождитесь, когда Вам на электронную почту придет письмо об успешной активации Вашего профиля в личном кабинете*/}
                                {/*(активация может занять до 24 часов и только в рабочие дни недели)*/}
                            {/*</p>*/}
                        {/*</WhiteBox>*/}
                    {/*:''}*/}
                {/*</div>*/}



            </PageWrapper>
    </div>
    );
};

export default HomePage;