import {extendToken} from '../api/User';

export async function tryRefreshTokens(errorMessage) {
        if (isTokenIssue(errorMessage)){
            return extendToken().then(tokens => {
                if(tokens.access && tokens.refresh){
                    return tokens;
                }else{
                    throw new Error('Не удалось обновить токен');
                }
            });
        }else
            return false;
}

export function isTokenIssue(errorMessage) {
    return errorMessage.indexOf('jwt') !== -1;
}