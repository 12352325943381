import React, {useContext} from 'react';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import UserContext from '../context/UserContext';
import TextHeader from '../components/common/TextHeader';
import PersonalDataBlock from '../components/profile/PersonalDataBlock';
import {useRefreshTokensOnError} from '../hooks/useRefreshTokens';

const ProfilePage = () => {
    const {user, saveUser, tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    return (
        <>
            <AppHeader/>
            <PageWrapper>
                <TextHeader>Профиль</TextHeader>
                <PersonalDataBlock user={user}/>
            </PageWrapper>
        </>
    );
};

export default ProfilePage;