import React, {useContext, useState} from 'react';
import ActionWithConfirmation from '../common/ActionWithConfirmation';
import RequestContext from '../../context/RequestContext';
import moment from 'moment';
import NewDealForm from './NewDealForm';
import WhiteBox from '../common/WhiteBox';
import {RequestStatusTypes} from '../../utils/LeadUtils';
const StartDealBlock = () => {
    const {request, setRequest} = useContext(RequestContext);
    const [showForm, setShowForm] = useState(false);

    const determineSubtitle = () => {
        if(request.dealAttachments){
            return  `Документы отправлены ${moment(request.dealAttachments[0].createdAt).format('DD.MM.YYYY в HH:mm')}`;
        }else if (request.cancellationRequestedAt){
            return 'Действие недоступно, так как был отправлен запрос на отмену заявки';
        }else if(request.status !== RequestStatusTypes.REQUEST_SAVED){
            return 'Действие не является актуальным для данной заявки';
        }else{
            return 'Вы можете скачать и прикрепить к заявке документы, необходимые для оформления лизинга';
        }
    };

    return (
        <div>
            {!request.dealAttachments && !request.cancellationRequestedAt && showForm ?
                <WhiteBox>
                    <NewDealForm onCancelClick={() => setShowForm(false)}/>
                </WhiteBox>
                :
                <ActionWithConfirmation title='Скачать и прикрепить документы'
                                        subtitle={determineSubtitle()}
                                        confirmationText='После завершения процедуры заявка будет отправлена в А-Лизинг. Продолжить?'
                                        actionButtonTitle='Прикрепить документы'
                                        disabled={request.dealAttachments || request.cancellationRequestedAt || request.status !== RequestStatusTypes.REQUEST_SAVED}
                                        actionToExecute={() => setShowForm(true)}
                />
            }
        </div>
    );
};

export default StartDealBlock;