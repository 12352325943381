import React from 'react';
import * as styles from './WhiteBox.module.css';

const WhiteBox = ({children, style, className}) => {
    return (
        <div className={`${styles.container} ${className ? className : ''}`} style={style}>
            {children}
        </div>
    );
};

export default WhiteBox;