import React, {useContext, useEffect, useState} from 'react';
import {getUserLeadRequest} from '../api/Lead';
import {isTokenIssue} from '../utils/ApiUtils';
import UserContext from '../context/UserContext';
import RequestContext from '../context/RequestContext';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import { useParams } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import RequestDataBlock from '../components/request/RequestDataBlock';
import RequestPaymentSchedule from '../components/request/RequestPaymentSchedule';
import {UserRoles} from '../utils/UserUtils';


const RequestPage = () => {
    const {id} = useParams();

    const [request, setRequest] = useState(null);
    const {user, tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(false);
    const [statusCode, setStatusCode] = useState(false);


    const getRequest = () => {
        return getUserLeadRequest(id).then(request => {
            setRequest(request);
        }).catch(error =>{
            if (isTokenIssue(error.message))
                throw error;
            else{
                setErrorMessage(error.message);
                setStatusCode(error.statusCode);
            }
        });
    };

    const wrappedGetRequest = async () =>{
        try{
            await getRequest();
        }catch (error) {
            tryRefreshTokensAndRepeatAction(error.message, ()=>getRequest());
        }
    };

    useEffect(async ()=>{
        wrappedGetRequest();
    }, []);

    return (
        <>
            <AppHeader/>
            <PageWrapper>
                <RequestContext.Provider value={{request, setRequest}}>
                    {errorMessage ? <NotFoundPage text={errorMessage} code={statusCode}/>: ''}
                    {request ?  <RequestDataBlock/>  : ''}
                    {request && request.calculatorParams && (user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ?  <RequestPaymentSchedule/>  : ''}
                </RequestContext.Provider>
            </PageWrapper>
        </>

    );
};

export default RequestPage;