import React from 'react';
import * as styles from './RequestListHeader.module.css';

const RequestListHeader = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.cell}>
                Дата
            </div>
            <div className={styles.cell} style={{flexBasis: '50%'}}>
                Статус
            </div>
            <div className={styles.cell}>
                ФИО
            </div>
            <div className={styles.cell}>
               Телефон
            </div>
            <div className={styles.cell}>
                Автомобиль
            </div>
            <div className={styles.cell} style={{flexBasis: '70%'}}>
                Стоимость
            </div>
        </div>
    );
};

export default RequestListHeader;