import React, {useContext, useEffect, useState} from 'react';
import * as styles from './PaymentSchedule.module.css';
import UserContext from '../../context/UserContext';
import PaymentScheduleContext from '../../context/PaymentScheduleContext';
import {isTokenIssue} from '../../utils/ApiUtils';
import {getPaymentSchedule} from '../../api/Calculator';
import MonthlyPaymentList from './MonthlyPaymentList';
import LoadingIndicator from '../common/LoadingIndicator';
import WhiteBox from '../common/WhiteBox';
import PrintDocHeader from '../common/PrintDocHeader';
import formatPhone from '../../utils/formatPhone';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const PaymentSchedule = ({params}) => {
    const {user, tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(false);
    const {paymentSchedule, setPaymentSchedule, loadingSchedule, setLoadingSchedule} = useContext(PaymentScheduleContext);
    const maxPrepayPercentage = 40;

    const getSchedule = () => {
        setLoadingSchedule(true);
        return getPaymentSchedule(params).then(newSchedule => {
            setErrorMessage(false);
            setLoadingSchedule(false);
            setPaymentSchedule(newSchedule);
        }).catch(error =>{
            setLoadingSchedule(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                setErrorMessage(error.message);
            }
        });
    };

    const wrappedGetSchedule = async () =>{
        try{
            await getSchedule();
        }catch (error) {
            setLoadingSchedule(true);
            tryRefreshTokensAndRepeatAction(error.message, ()=>getSchedule());
        }
    };

    useEffect(()=>{
        if(!params){
            setPaymentSchedule(null);
        }else if(params && params.algo && params.currency && params.price &&
                params.prepayPercentage && params.termMonths && params.clientType){
            wrappedGetSchedule();
        }
    }, [params]);

    const generatePrepayPercentagesLabel = () => {
        let label = 'Авансовый платёж';
        if(params?.prepayPercentage){
            const prepayPercentage = parseInt(params.prepayPercentage);
            if(prepayPercentage > maxPrepayPercentage){
                const diff = prepayPercentage - maxPrepayPercentage;
                const actualPrepayPercentage = prepayPercentage - diff;
                label += ` (${actualPrepayPercentage}% + ${diff}% в первом платеже)`;
            }else{
                label += ` (${params.prepayPercentage}%)`;
            }
        }
        return label;
    };

    return (
        <div className={`${styles.wrapper} printableArea`}>
            {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : ''}
            {paymentSchedule ?
                <WhiteBox className={styles.wb}>
                    <PrintDocHeader/>
                    <h3 className={styles.scheduleHeader}>Предварительный расчёт лизинговых платежей</h3>
                    <div className={styles.commonDataRows}>
                        <div className={styles.dataRow}>
                            <span className={styles.label}>Контрактная стоимость предмета лизинга с НДС:</span>
                            {/*<span className={styles.value}>{parseFloat(params?.price).toFixed(2)} {params?.currency}</span>*/}
                            <span className={styles.value}>{formatNumberWithSpaces(params?.price)} {params?.currency}</span>
                        </div>
                        <div className={styles.dataRow}>
                            <span className={styles.label}>{generatePrepayPercentagesLabel()}:</span>
                            <span className={styles.value}>{formatNumberWithSpaces(paymentSchedule.prepayAmount)} {params?.currency}</span>
                        </div>
                        <div className={styles.dataRow}>
                            <span className={styles.label}>Срок лизинга:</span>
                            <span className={styles.value}>{params?.termMonths} мес.</span>
                        </div>
                        <div className={styles.dataRow}>
                            <span className={styles.label}>Выкупная стоимость:</span>
                            <span className={styles.value}>{formatNumberWithSpaces(paymentSchedule.ransom)} {params?.currency}</span>
                        </div>
                    </div>
                    <div className={styles.disclaimer}>Все расчёты предварительные и носят справочный характер. Окончательный расчёт после согласования спецификации предмета лизинга и предоставления пакета документов для принятия решения</div>
                    <MonthlyPaymentList months={paymentSchedule?.months}  prepayAmount={paymentSchedule?.prepayAmount}  />
                    <div className={styles.printedContactInfo}>
                        <b>По вопросам Вы можете обращаться:</b>
                        <span>{user?.surname} {user?.name} {user?.patronymic},</span><br/>
                        {user?.phone ? <span>+375 {formatPhone(user.phone.slice(-9))},</span> : ','}
                        <span>{user?.email}</span>
                    </div>
                </WhiteBox>
                : ''
            }
            <LoadingIndicator shown={loadingSchedule} inline={!paymentSchedule}/>
        </div>
    );
};

export default PaymentSchedule;