import React from 'react';
import styles from './RegisterPage.module.css';
import AuthFormWrapper from '../components/form/AuthFormWrapper';
import RegisterForm from '../components/form/RegisterForm';

const RegisterPage = () => {
    return (
        <div className={styles.formWrapper}>
            <AuthFormWrapper>
                <RegisterForm/>
            </AuthFormWrapper>
        </div>
    );
};

export default RegisterPage;