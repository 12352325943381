import React, {useState, useContext} from 'react';
import styles from './RegisterForm.module.css';
import FormInput from './FormInput';
import ArrowButton from '../common/ArrowButton';
import {register} from '../../api/User';
import LoadingIndicator from '../common/LoadingIndicator';
import { useHistory, Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import TabList from './TabList';
import {preHashPassword} from '../../utils/AuthUtils';

const RegisterForm = () => {
    const {user, saveUser} = useContext(UserContext);
    const routerHistory = useHistory();
    const [loading, setLoading] = useState(false);
    const formStatusTypes = {NONE: 0, SUCCESS: 1, FAIL: 2};
    const [formStatus, setFormStatus] = useState(formStatusTypes.NONE);

    const [lastSubmitError, setLastSubmitError] = useState('');

    const initialFormInputs = {
        surname: {categoryIndex: 1, value: '', valid: false, touched: false, placeholder: 'Фамилия', maxLength: 48},
        name: {categoryIndex: 1, value: '', valid: false, touched: false, placeholder: 'Имя', maxLength: 48},
        patronymic: {categoryIndex: 1, value: '', valid: false, touched: false, placeholder: 'Отчество', maxLength: 48},
        phone: {categoryIndex: 1, value: '', valid: false, touched: false,  placeholder: '(__) ___-__-__', maxLength: 14, type: 'phone'},
        payerAccountNumber: {categoryIndex: 2, value: '', valid: false, touched: false, placeholder: 'УНП', maxLength: 9},
        companyName: {categoryIndex: 2, value: '', valid: false, touched: false, placeholder: 'Наименование', maxLength: 48},
        companyAddress: {categoryIndex: 2, value: '', valid: false, touched: false, placeholder: 'Адрес', maxLength: 128},
        email: {categoryIndex: 3, value: '', valid: false, touched: false, placeholder: 'Адрес электронной почты', maxLength: 128},
        password: {categoryIndex: 3, value: '', valid: false, touched: false, placeholder: 'Пароль', maxLength: 128, type: 'password'},
    };
    const [formInputs, setFormInputs] = useState(initialFormInputs);
    const getCleanPhone = (formattedPhone) => {
        return formattedPhone.replace(/\D/g, '')
    };
    const validators = {
        password: (value) => {
            return value.length >= 6;
        },
        email: (value) => {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value);
        },
        payerAccountNumber: (value) => {
            return /^[\d]{9}$/.test(value);
        },
        phone: (value) => {
            return /^[\d]{9}$/.test(getCleanPhone(value));
        },
        surname: (value) => {
            return value.length >= 2;
        },
        name: (value) => {
            return value.length >= 2;
        },
        patronymic: (value) => {
            return value.length >= 2;
        },
        companyAddress: (value) => {
            return value.length >= 10;
        },
        companyName: (value) => {
            return value.length >= 3;
        }
    };

    let handleInputChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        const isValid = validators[name] ? validators[name](value) : true;
        setFormInputs({...formInputs, [name]: {...formInputs[name], value: value, valid: isValid, touched: true}});
        setFormStatus(formStatusTypes.NONE);
    };

    let markInvalidFieldsAsTouched = () => {
        let formDataCopy = {...formInputs};
        for (let [key, value] of Object.entries(formDataCopy)){
            if(!value.valid)
                value.touched = true;
        }
        setFormInputs(formDataCopy);
    };

    let handleSubmit = async (event) => {
        event.preventDefault();

        const allFieldsValid = Object.keys(formInputs).every(key => formInputs[key].valid === true);
        if (allFieldsValid){
            const dataToSubmit = {};
            for (let key in formInputs){
                if(formInputs.hasOwnProperty(key)){
                    dataToSubmit[key] = formInputs[key].value;
                }
            }
            try {
                dataToSubmit['password'] = await preHashPassword(dataToSubmit['password']);
                dataToSubmit['phone'] = `+375${getCleanPhone(dataToSubmit['phone'])}`;
                setLoading(true);
                register(dataToSubmit)
                    .then(processSuccessfulSubmit)
                    .catch(processFailedSubmit);
            }catch (e) {
                processFailedSubmit(e);
            }
        }else{
            markInvalidFieldsAsTouched();
        }
    };

    let processSuccessfulSubmit = (userData) => {
        setLoading(false);
        if(userData?.accessToken){
            setFormInputs(initialFormInputs);
            setFormStatus(formStatusTypes.SUCCESS);

            saveUser(userData);
            routerHistory.push('/');
        }
    };

    let processFailedSubmit = (error) => {
        setFormStatus(formStatusTypes.FAIL);
        setLastSubmitError(error.message);
        setLoading(false);
        if (error instanceof TypeError) {
            alert('Проверьте подключение к интернету.');
        }
    };

    const renderFieldsByCategoryIndex = (categoryIndex) => {
        return Object.entries(formInputs).filter(([key, field]) => field.categoryIndex === categoryIndex).map(([key, field]) => {
            return (
                <FormInput key={key} name={key} placeholder={field.placeholder} type={field.type? field.type: 'text'}
                           value={field.value} valid={field.valid} touched={field.touched}
                           onChange={handleInputChange} maxLength={field.maxLength}/>
            );
        });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            <h2 className={styles.formTitle}>Личный кабинет</h2>
            <h3 className={styles.formSubtitle}>Создание учетной записи</h3>
            <h3 className={styles.fieldCategoryTitle}>Сотрудник</h3>
            {renderFieldsByCategoryIndex(1)}
            <h3 className={styles.fieldCategoryTitle}>Организация</h3>
            {renderFieldsByCategoryIndex(2)}
            <h3 className={styles.fieldCategoryTitle}>Учетные данные</h3>
            {renderFieldsByCategoryIndex(3)}
            {formStatus === formStatusTypes.FAIL ? <div className={styles.errorContainer}>{lastSubmitError}</div> : ''}
            <div className={styles.buttonsContainer}>
                <Link to="/login" style={{textDecoration: 'none'}}>
                    <ArrowButton filled={false} type="button">Eсть аккаунт?</ArrowButton>
                </Link>
                <ArrowButton type="submit">Зарегистрироваться</ArrowButton>
            </div>
            <LoadingIndicator shown={loading} style={{top: '-5px', left: '-5px', right: '-5px', bottom: '-5px'}}/>
        </form>
    );
};

export default RegisterForm;