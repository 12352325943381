import React from 'react';
import * as styles from './RequestListItem.module.css';
import moment from 'moment';
import {Link} from 'react-router-dom';
import formatPhone from '../../utils/formatPhone';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const RequestListItem = ({request}) => {
    return (
        <Link to={`/request/${request.link}`}  target="_blank" className={styles.wrapper}>
            <div className={styles.tagsContainer}>
                {request.cancellationRequestedAt ? <div className={styles.tag}>запрошена отмена</div> : ''}
                {request.dealAttachments ? <div className={styles.tag}>документы прикреплены</div> : ''}
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Дата</span>
                <div className={styles.value}>{moment(request.createdAt).format('DD.MM.YYYY HH:mm')}</div>
            </div>
            <div className={styles.cell} style={{flexBasis: '50%'}}>
                <span className={styles.label}>Статус</span>
                <div className={styles.value}>{request.statusLabel || '-'}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>ФИО</span>
                <div className={styles.value}>{request.surname} {request.name} {request.patronymic}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Телефон</span>
                <div className={styles.value}>+375 {formatPhone(request.phone?.slice(-9))}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Автомобиль</span>
                <div className={styles.value}>{request.carName}</div>
            </div>
            <div className={styles.cell} style={{flexBasis: '70%'}}>
                <span className={styles.label}>Стоимость</span>
                <div className={styles.value}>{formatNumberWithSpaces(request?.price)} {request.currency}</div>
            </div>
        </Link>
    );
};

export default RequestListItem;