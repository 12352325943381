import React from 'react';
import styles from './LoadingIndicator.module.css';
import {ReactComponent as Logo} from '../../assets/images/logo.svg'

const LoadingIndicator = ({shown, inline, style, withText}) => {
    return (
        <div style={style} className={`${!inline ? styles.wrapperAbsolute : styles.wrapperInline} ${!shown ? styles.hidden : ''}`}>
            <Logo className={styles.loaderImage}/>
            {withText ? <div className={styles.label}>{withText}</div> : ''}
        </div>
    );
};

export default LoadingIndicator;