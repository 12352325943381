import {getAuthHeader} from '../utils/AuthUtils';
import ApplicationError from '../utils/ApplicationError';
const APIRoot = process.env.REACT_APP_API_ROOT || '/api/';

export function submitLeadRequest(requestData) {
    return fetch(`${APIRoot}/lead-request/create`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function cancelLeadRequest(requestLink) {
    return fetch(`${APIRoot}/lead-request/cancel`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({requestLink: requestLink})
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function getLeadRequests(batchSize, batchIndex) {
    return fetch(`${APIRoot}/lead-requests/${batchSize}/${batchIndex}/`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}
export function getUserLeadRequest(id) {
    return fetch(`${APIRoot}/lead-request/${id}`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function createDeal(requestLink, formData) {
    return fetch(`${APIRoot}/lead-request/${requestLink}/deal/create`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
        },
        body: formData
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function getRequiredDealAttachments() {
    return fetch(`${APIRoot}/lead-request/deal/attachment-types`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function setLeadRequestStatusToSubmitted(requestLink, comment) {
    return fetch(`${APIRoot}/lead-request/set-status/submitted`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({requestLink: requestLink, comment: comment})
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function updateLeadRequestComment(requestLink, comment) {
    return fetch(`${APIRoot}/lead-request/${requestLink}/comment/update`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({comment: comment})
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}
