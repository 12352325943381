import React from 'react';
import styles from './ArrowButton.module.css';
import {ReactComponent as SmallArrowIcon} from '../../assets/images/small-arrow-icon.svg';

const ArrowButton = ({children, onClick, disabled, type, filled = true, reversed}) => {
    return (
        <button className={`${styles.button} ${filled ? styles.filled : styles.outlined} ${reversed ? styles.reversed : ''}`} disabled={disabled} onClick={onClick} type={type}>{children}<SmallArrowIcon/></button>
    );
};

export default ArrowButton;