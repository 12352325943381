import React from 'react';
import * as styles from './MonthlyPaymentList.module.css';
import MonthlyPaymentListItem from './MonthlyPaymentListItem';
import MonthlyPaymentListHeader from './MonthlyPaymentListHeader';
import MonthlyPaymentListItemTotal from './MonthlyPaymentListItemTotal';
const MonthlyPaymentList = ({months, prepayAmount}) => {
    return months ?
                <div className={styles.wrapper}>
                    <div className={styles.headerWrapper}>
                        <MonthlyPaymentListHeader/>
                    </div>
                    {months?.map(m => <MonthlyPaymentListItem key={m.monthNum} month={m}/>)}
                    <MonthlyPaymentListItemTotal months={months} prepayAmount={prepayAmount}/>
                </div>
        :'';
};

export default MonthlyPaymentList;