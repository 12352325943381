import React from 'react';
import styles from './JoinedTabList.module.css'
import JoinedTabItem from './JoinedTabItem';

const JoinedTabList = ({tabs, activeTabIndex, onTabClick, className}) => {
    const maxTabWidthPercentage = tabs.length > 1 ? Math.floor(1/tabs.length * 100) : 100;
    return (
        <div className={`${styles.tabList} ${className}`} >
            {tabs.map((tab, index) =>
                <JoinedTabItem key={index} name={tab.name} index={index}
                               active={activeTabIndex === index} onClick={onTabClick}
                               style={{width: `${maxTabWidthPercentage}%`, maxWidth: '100%'}}/>)}
        </div>
    );
};

export default JoinedTabList;