import React, {useContext, useEffect, useState} from 'react';
import * as styles from './RequestPaymentSchedule.module.css';
import WhiteBox from '../common/WhiteBox';
import RequestContext from '../../context/RequestContext';
import PaymentScheduleContext from '../../context/PaymentScheduleContext';

import PaymentSchedule from '../calculator/PaymentSchedule';
import LargeButton from '../common/LargeButton';

const RequestPaymentSchedule = () => {
    const {request} = useContext(RequestContext);
    const [paymentSchedule, setPaymentSchedule] = useState(null);
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [calcParamsForScheduleRender, setCalcParamsForScheduleRender] = useState(null);
    useEffect(()=>{
        setCalcParamsForScheduleRender({...request.calculatorParams, price: request.price, currency: request.currency, clientType: request.clientType});
    }, [request]);
    return (
        <PaymentScheduleContext.Provider value={{paymentSchedule, setPaymentSchedule, loadingSchedule, setLoadingSchedule}}>
            <WhiteBox>
                <h3 className={styles.header}>
                    График платежей
                </h3>
                <div className={styles.printButtonWrapper}>
                    <LargeButton className={styles.printButton} filled={false} onClick={() => window.print()}>Распечатать график</LargeButton>
                </div>
                <PaymentSchedule params={calcParamsForScheduleRender}/>
            </WhiteBox>
        </PaymentScheduleContext.Provider>
    );
};

export default RequestPaymentSchedule;