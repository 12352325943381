import React from 'react';
import styles from './NotFoundPage.module.css';
import ArrowButton from '../components/common/ArrowButton';
import {Link} from 'react-router-dom';
import {usePageTitle} from '../hooks/usePageTitle';

const NotFoundPage = ({text, code}) => {
    usePageTitle('Страница не найдена');
    return (
        <div className={styles.wrapper}>
            <h1 className={styles.statusCode}>{code ? code : 404}</h1>
            <p className={styles.statusText}>{text ? text : 'Страница не найдена'} <span className={styles.decoration}>:(</span></p>
            <Link to={{pathname: '/', search: window.location.search}}><ArrowButton>На главную</ArrowButton></Link>
        </div>
    );
};

export default NotFoundPage;