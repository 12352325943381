import React, {useState, useEffect, useCallback} from 'react';
import * as styles from './FileDropInput.module.css';
import {useDropzone} from 'react-dropzone';
import FileDropInputItem from '../common/FileDropInputItem';

const FileDropInput = ({onFilesChange, multiple, acceptedExt}) => {
    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: multiple, accept: acceptedExt});

    const handleRemoveClick = file =>{
        const index = files.findIndex(f => f.path === file.path);
        if(index !== -1){
            files.splice(index, 1);
            setFiles([...files]);
        }
    };

    useEffect(()=>{
        onFilesChange(files);
    }, [files]);

    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {!files?.length ?
                    <p className={`${styles.dropArea} ${isDragActive ? styles.active : ''}`}>
                        <span>Перетащите файл</span>
                        {acceptedExt && acceptedExt.length ? <span className={styles.allowedExtList}> ({acceptedExt.join(', ')}) </span> : ''}
                        <span> или нажмите</span></p>
                    : ''
                }
            </div>
            {files?.length ? <div className={styles.filesContainer}>
                {files.map(f => <FileDropInputItem key={f.path} file={f} onRemoveClick={handleRemoveClick}/>)}
            </div> : ''}
        </div>
    );
};

export default FileDropInput;