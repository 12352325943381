import React from 'react';
import * as styles from './PersonalDataBlock.module.css';
import PersonalDataRow from './PersonalDataRow';
import {UserRoles} from '../../utils/UserUtils';
import WhiteBox from '../common/WhiteBox';
import EmailConfirmationBlock from './EmailConfirmationBlock';
import StartDealBlock from '../request/StartDealBlock';

const PersonalDataBlock = ({user}) => {
    const userRoleNames = {};
    userRoleNames[UserRoles.BANK] = 'Сотрудник банка';
    userRoleNames[UserRoles.DEALER] = 'Сотрудник дилера';
    userRoleNames[UserRoles.TEST] = 'Сотрудник дилера (тест)';
    userRoleNames[UserRoles.OTHER] = 'Другой партнёр';
    return (
        <div className={styles.wrapper}>
            <WhiteBox>
                <h4 className={styles.subHeader}>Учётная запись {!user.activated ? <span className={styles.activationDisclaimerAsterisk}>*</span> : ''}</h4>
                <PersonalDataRow label='Статус' value={user.activated ?
                    <span className={styles.colorGreen}>активирована</span>
                    :
                    <span className={styles.colorRed}>не активирована</span>}
                />
                <PersonalDataRow label='Роль' value={userRoleNames[user.role]}/>
                <PersonalDataRow label='Адрес email' value={<span>{user.email} {user.emailConfirmed ?
                    <span className={styles.colorGreen}>(подтверждён)</span>
                    :
                    <span className={styles.colorRed}>(не подтверждён)</span>}
                                                        </span>}
                />
                {!user.emailConfirmed ? <EmailConfirmationBlock/> :''}
                {!user.activated ?
                    <p className={styles.activationDisclaimer}>
                        <span className={styles.activationDisclaimerAsterisk}>*</span> Ваша учётная запись не активирована.
                        Вы сможете приступить к работе после того, как учётная запись пройдёт процедуру модерации
                    </p>
                    : ''
                }
            </WhiteBox>
            <WhiteBox>
                <h4 className={styles.subHeader}>Сотрудник</h4>
                <PersonalDataRow label='Фамилия' value={user.surname}/>
                <PersonalDataRow label='Имя' value={user.name}/>
                <PersonalDataRow label='Отчество' value={user.patronymic}/>
                <PersonalDataRow label='Телефон' value={user.phone}/>
            </WhiteBox>
            <WhiteBox>
                <h4 className={styles.subHeader}>Организация</h4>
                <PersonalDataRow label='УНП' value={user.payerAccountNumber}/>
                <PersonalDataRow label='Наименование' value={user.companyName}/>
                <PersonalDataRow label='Адрес' value={user.companyAddress}/>
                {((user.role === UserRoles.DEALER || user.role === UserRoles.TEST) && !user.dealer?.name) ? <div className={styles.colorRed}>Внимание: не назначен дилер, калькулятор недоступен</div>: ''}
            </WhiteBox>
        </div>
    );
};

export default PersonalDataBlock;