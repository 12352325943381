import React, {useContext} from 'react';
import * as styles from './HeaderLinks.module.css';
import {Link} from 'react-router-dom';
import UserContext from '../../context/UserContext';
import {UserRoles} from '../../utils/UserUtils';
import PageWrapper from '../common/PageWrapper';

const HeaderLinks = ({onLinkClick}) => {
    const {user} = useContext(UserContext);
    return (
        <div className={styles.linksContainer}>
            <Link className={styles.link} to="/" onClick={onLinkClick}>Главная</Link>
            {user.activated ?
                 <>
                     <Link className={styles.link} to="/create-request" onClick={onLinkClick}>{(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? 'Расчёт платежей' : 'Создать заявку'}</Link>
                     <Link className={styles.link} to="/requests" onClick={onLinkClick}>Список заявок</Link>
                     <a  className={styles.link} href={`${process.env.PUBLIC_URL}/reference.pdf`} target='_blank' referrerPolicy="no-referrer">Справка</a>
                 </>
            : ''}

        </div>
    );
};

export default HeaderLinks;