import React, {useEffect, useState} from 'react';
import PageWrapper from '../components/common/PageWrapper';
import { Link } from 'react-router-dom';
import ArrowButton from '../components/common/ArrowButton';
import * as styles from './EmailConfirmationPage.module.css';
import WhiteBox from '../components/common/WhiteBox';
import LoadingIndicator from '../components/common/LoadingIndicator';
import {confirmEmail} from '../api/User';
import { useParams } from 'react-router-dom';

const EmailConfirmationPage = () => {
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [confirmed, setConfirmed] = useState(false);


    const confirmEmailByToken = () => {
        setLoading(true);
        confirmEmail(token).then(()=>{
            setConfirmed(true);
            setLoading(false);
        }).catch(error => {
            if (error.message.indexOf('jwt expired') !== -1){
                setErrorMessage('Ссылка устарела. Запросите новую ссылку на странице профиля');
            }else{
                setErrorMessage(error.message);
            }
            setLoading(false);
        });
    };
    useEffect(()=>{
        confirmEmailByToken();
    }, [token]);
    return (
        <div>
            <PageWrapper>
                <div className={styles.wrapper}>
                    <WhiteBox className={styles.container}>
                        <h2 className={styles.header}>Подтверждение адреса электронной почты</h2>
                        {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : ''}
                        {confirmed && !errorMessage ? <div className={styles.confirmed}>Адрес подтверждён</div> : ''}
                        <LoadingIndicator shown={loading} withText={'Проверка токена...'} inline/>
                        <Link to="/">
                            <ArrowButton filled={false}>На главную</ArrowButton>
                        </Link>
                    </WhiteBox>
                </div>
            </PageWrapper>
        </div>
    );
};

export default EmailConfirmationPage;