import React, {useContext} from 'react';
import * as styles from './RequestDataBlock.module.css';
import PersonalDataRow from '../profile/PersonalDataRow';
import formatPhone from '../../utils/formatPhone';
import WhiteBox from '../common/WhiteBox';
import moment from 'moment';
import {UserRoles} from '../../utils/UserUtils';
import {ClientTypes} from '../../utils/LeadUtils';
import UserContext from '../../context/UserContext';
import RequestContext from '../../context/RequestContext';
import StartDealBlock from './StartDealBlock';
import CancelRequestBlock from './CancelRequestBlock';
import SubmitSavedRequestBlock from './SubmitSavedRequestBlock';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const RequestDataBlock = () => {
    const {user} = useContext(UserContext);
    const {request} = useContext(RequestContext);
    const clientTypeLabels = {};
    clientTypeLabels[ClientTypes.PRIVATE] = 'Физ. лицо';
    clientTypeLabels[ClientTypes.BUSINESS] = 'Юр. лицо';
    return (
        <div>
            <WhiteBox>
                <h3 className={styles.header}>Заявка от {moment(request.createdAt).format('DD.MM.YYYY HH:mm')}
                    {request.cancellationRequestedAt ? <div className={styles.headerStatus}>запрошена отмена</div> : ''}
                    {request.dealAttachments ? <div className={styles.headerStatus}>документы прикреплены</div> : ''}
                </h3>
                <PersonalDataRow label='Статус' value={request.statusLabel}/>
                <h4 className={styles.subHeader}>Клиент</h4>
                <PersonalDataRow label='Тип клиента' value={clientTypeLabels[request.clientType]}/>
                <PersonalDataRow label='ФИО' value={`${request.surname} ${request.name} ${request.patronymic || ''}`}/>
                <PersonalDataRow label='Телефон' value={<a href={`tel:${request.phone}`}>+375 {formatPhone(request.phone.slice(-9))}</a>}/>

                {request.clientType === ClientTypes.BUSINESS ?
                    <>
                        <h4 className={styles.subHeader}>Организация</h4>
                        <PersonalDataRow label='Название' value={request.companyName}/>
                        <PersonalDataRow label='УНП' value={request.payerAccountNumber}/>
                    </>
                    : ''
                }
                <h4 className={styles.subHeader}>Автомобиль</h4>
                <PersonalDataRow label='Бренд и модель' value={request.carName}/>
                <PersonalDataRow label='Стоимость' value={`${formatNumberWithSpaces(request.price)} ${request.currency}`}/>

                {request.comment ? <>
                        <h4 className={styles.subHeader}>Комментарий</h4>
                        <div className={styles.requestComment}>{request.comment}</div>
                    </>
                    : ''
                }

                <h4 className={styles.subHeader}>Доступные действия</h4>
                {(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? <StartDealBlock/> : ''}
                {(user.role === UserRoles.DEALER || user.role === UserRoles.TEST) ? <SubmitSavedRequestBlock/> : ''}
                <CancelRequestBlock/>
            </WhiteBox>
        </div>
    );
};

export default RequestDataBlock;