export function getUserFromStorage() {
    return JSON.parse(localStorage.getItem('user'));
}

export function saveUserToStorage(userData) {
    localStorage.setItem('user', JSON.stringify(userData));
}

export function removeUserFromStorage(){
    localStorage.removeItem('user');
}

export const UserRoles = {
    BANK: 'bank',
    DEALER: 'dealer',
    TEST: 'test',
    OTHER: 'other',
};