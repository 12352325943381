import React from 'react';
import * as styles from './MonthlyPaymentListItemTotal.module.css';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const MonthlyPaymentList = ({months, prepayAmount}) => {
    const findSum = (fieldName)=>{
        return months.reduce((p, c) => parseFloat(p) + parseFloat(c[fieldName]), 0);
    };
    const findPaymentSum = ()=>{
        const paymentSum = findSum('payment');
        console.log(paymentSum);
        console.log(prepayAmount);
        return roundToTwoDecimals(paymentSum + parseFloat(prepayAmount)).toFixed(2);
    };

    const roundToTwoDecimals = (num) => {
        if (isNaN(num))
            return num;
        const decimalPlaces = 2;
        const p = Math.pow(10, decimalPlaces);
        const n = (num * p) * (1 + Number.EPSILON);
        return Math.round(n) / p;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.cell}>
                <span className={styles.label}></span>
                <div className={styles.value}>Итого</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}></span>
                <div className={styles.value}>{}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}></span>
                <div className={styles.value}>{}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}></span>
                <div className={styles.value}>{}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Сумма платежей, с НДС</span>
                <div className={styles.value}><b>{formatNumberWithSpaces(findPaymentSum())}</b></div>
            </div>
        </div>
    );
};

export default MonthlyPaymentList;