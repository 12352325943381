import React from 'react';
import * as styles from './PrintDocHeader.module.css';
import {ReactComponent as Logo} from '../../assets/images/logo-compact.svg'

const PrintDocHeader = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
                <b>ООО «А-Лизинг»</b>
                <div>Ул. Сурганова, д. 29, пом. 12</div>
                <div>220012, Минск,</div>
                <div>Республика Беларусь</div>
                <div style={{marginTop: '10px'}}>Tel: +375 (44) 701 70 70</div>
                <div>a-leasing.by</div>
            </div>
            <div className={styles.logoWrapper}>
                <Logo/>
            </div>
        </div>
    );
};

export default PrintDocHeader;