import React from 'react';
import * as styles from './PersonalDataRow.module.css';

const PersonalDataRow = ({label, value}) => {
    return (
        <div className={`${styles.row} ${!value ? styles.empty : ''}`}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value ? value : '-'}</div>
        </div>
    );
};

export default PersonalDataRow;