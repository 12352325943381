import React from 'react';
import styles from './TabList.module.css'
import TabItem from './TabItem';

const TabList = ({tabs, activeTabIndex, isVertical, onTabClick}) => {
    return (
        <div className={`${styles.tabList} ${isVertical ? styles.vertical : ''}`}>
            {tabs.map((tab, index) =>
                <TabItem key={index} name={tab.name} index={index} active={activeTabIndex === index} onClick={onTabClick} isVertical={isVertical}/>)}
        </div>
    );
};

export default TabList;