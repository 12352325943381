import React, {useContext, useState} from 'react';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import NewRequestForm from '../components/new-request/NewRequestForm';
import LeadRequestSubmissionResult from '../components/new-request/LeadRequestSubmissionResult';
import UserContext from '../context/UserContext';
import CalculatorParamsContext from '../context/CalculatorParamsContext';
import PaymentScheduleContext from '../context/PaymentScheduleContext';
import {UserRoles} from '../utils/UserUtils';
import WhiteBox from '../components/common/WhiteBox';
import LoadingIndicator from '../components/common/LoadingIndicator';

const CreateRequestPage = () => {
    const {user} = useContext(UserContext);
    const [submittedLead, setSubmittedLead] = useState(null);
    const [finished, setFinished] = useState(false);
    const [loadingSchedule, setLoadingSchedule] = useState(false);

    const initialCalculatorParams = {
        algo: 'differential',
        currency:  'BYN',
        price: null,
        prepayPercentage: null,
        termMonths: null,
        clientType: 'private'
    };

    const [calculatorParams, setCalculatorParams] = useState(initialCalculatorParams);
    const [paymentSchedule, setPaymentSchedule] = useState(null);

    const handleLeadSubmitted = (lead) => {
        setSubmittedLead(lead);
        setFinished(true);
        setCalculatorParams(initialCalculatorParams);
    };

    const handleFinishButtonClick = () => {
        setSubmittedLead(null);
        setFinished(false);
    };

    return (
        <>
            <AppHeader/>
            <PageWrapper>
                <PaymentScheduleContext.Provider value={{paymentSchedule, setPaymentSchedule, loadingSchedule, setLoadingSchedule}}>
                    <CalculatorParamsContext.Provider value={{calculatorParams, setCalculatorParams}}>
                        {!finished ?
                            <WhiteBox>
                                <div style={{position: 'relative'}}>
                                    <LoadingIndicator shown={loadingSchedule}/>

                                    <NewRequestForm successfulSubmitCallback={handleLeadSubmitted}
                                                    withCalculator={(user.role === UserRoles.DEALER || user.role === UserRoles.TEST)}/>
                                </div>
                            </WhiteBox>
                            : ''}
                        {finished ? <LeadRequestSubmissionResult submittedLead={submittedLead} finishButtonCallback={handleFinishButtonClick}/> : ''}
                    </CalculatorParamsContext.Provider>
                </PaymentScheduleContext.Provider>
            </PageWrapper>
        </>
    );
};

export default CreateRequestPage;