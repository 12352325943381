import React, {useEffect, useState} from 'react';
import * as styles from './AppHeader.module.css';
import {ReactComponent as Logo} from '../../assets/images/logo.svg'
import HeaderLinks from './HeaderLinks';
import BurgerButton from './BurgerButton';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {Link} from 'react-router-dom';
import AppHeaderProfile from './AppHeaderProfile';

const AppHeader = () => {
    const [contentMobileExpanded, setContentMobileExpanded] = useState(false);

    useEffect(() => {
        if(contentMobileExpanded){
            disableBodyScroll(document.getElementsByName('body'));
        }else{
            enableBodyScroll(document.getElementsByName('body'));
        }
    }, [contentMobileExpanded]);

    const onLinkClick = () => {
        setContentMobileExpanded(false);
    };

    return (
        <header className={`${styles.appHeaderWrapper} ${contentMobileExpanded ? styles.mobileExpanded : ''}`}>
            <div className={styles.appHeader}>
                <Link to='/' className={styles.logoLink}>
                    <Logo className={styles.logoImage}/>
                </Link>
                <div className={styles.interactiveItemsWrapper}>
                    <HeaderLinks onLinkClick={onLinkClick}/>
                    <div className={styles.contactButtonWrapper}>
                        <AppHeaderProfile onLinkClick={onLinkClick}/>
                    </div>
                </div>
                <BurgerButton isExpanded={contentMobileExpanded} color={`${contentMobileExpanded ? '#ef3124' : 'white'}`} onClick={() => {setContentMobileExpanded(!contentMobileExpanded)}}>Menu</BurgerButton>
            </div>
        </header>
    );
};

export default AppHeader;