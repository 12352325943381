import * as argon2 from 'argon2-browser';
import cryptoRandomString from 'crypto-random-string';
import {getUserFromStorage} from './UserUtils';

export async function preHashPassword(plaintextPassword, providedSalt = false){
    try{
        const clientSalt = providedSalt ? providedSalt : cryptoRandomString({length: 16, type: 'base64'});
        const result = await argon2.hash({pass: plaintextPassword, salt: clientSalt, type: argon2.ArgonType.Argon2id});
        return result.encoded;
    }catch (e) {
        let errorMessage = `Не удалось безопасно преобразовать пароль. ${!providedSalt ? 'Попробуйте ввести другой вариант' : 'Повторите попытку позже'}`;
        throw Error(errorMessage);
    }
}

export function getAuthHeader(isRefresh = false){
    const user = getUserFromStorage();
    const token = isRefresh ? user?.refreshToken : user?.accessToken;
    return `Bearer ${token}`;
}