import React, {useContext, useEffect, useState} from 'react';
import AppHeader from '../components/header/AppHeader';
import PageWrapper from '../components/common/PageWrapper';
import TextHeader from '../components/common/TextHeader';
import {isTokenIssue} from '../utils/ApiUtils';
import UserContext from '../context/UserContext';
import {getLeadRequests} from '../api/Lead';
import RequestListItem from '../components/request-list/RequestListItem';
import RequestListHeader from '../components/request-list/RequestListHeader';
import * as styles from './RequestListPage.module.css';
import WhiteBox from '../components/common/WhiteBox';
import LoadingIndicator from '../components/common/LoadingIndicator';
import LargeButton from '../components/common/LargeButton';

const RequestListPage = () => {
    const {tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const batchSize = 15;
    const [batchIndex, setBatchIndex] = useState(0);
    const [lastLoadedBatchIndex, setLastLoadedBatchIndex] = useState(0);
    const [allLoaded, setAllLoaded] = useState(false);

    const getRequests = () => {
        setLoading(true);
        return getLeadRequests(batchSize, batchIndex).then(leadRequests => {
            if(!leadRequests.length){
                setAllLoaded(true);
            }else{
                setRequests(requests.concat(leadRequests));
                if(leadRequests.length < batchSize){
                    setAllLoaded(true);
                }
            }
            setLastLoadedBatchIndex(batchIndex);
            setLoading(false);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                if (batchIndex === 0){
                    setAllLoaded(true);
                }
                alert('Не удалось получить список заявок');
            }
        });
    };

    const wrappedGetRequests = async () =>{
        try{
            await getRequests();
        }catch (error) {
            setLoading(true);
            await tryRefreshTokensAndRepeatAction(error.message, async ()=>getRequests());
        }
    };

    useEffect(async ()=>{
        if(batchIndex === 0 || batchIndex > lastLoadedBatchIndex){
            wrappedGetRequests();
        }
    }, [batchIndex]);

    return (
        <>
            <AppHeader/>
            <PageWrapper>
                <div className={styles.listWrapper}>
                    <WhiteBox>
                        <div className={styles.headerWrapper}>
                            <RequestListHeader/>
                        </div>
                        <div className={styles.requestsWrapper}>
                            {requests.map((request, key) => <RequestListItem key={key} request={request}/>)}
                        </div>
                        {!loading && !requests?.length ? <div className={styles.emptyListText}>Здесь будут отображаться Ваши заявки</div> : ''}
                        {loading ? <LoadingIndicator shown={loading} withText='Загрузка заявок...' inline={true}/> : ''}
                        {!loading && !allLoaded ? <LargeButton className={styles.loadMoreButton} filled={false} onClick={() => setBatchIndex(batchIndex + 1)}>Загрузить ещё</LargeButton> : ''}
                        {allLoaded && batchIndex > 0 ? <div className={styles.allLoadedIndicator}>Все заявки загружены</div> : ''}
                    </WhiteBox>
                </div>
            </PageWrapper>
        </>
    );
};

export default RequestListPage;