import React from 'react';
import * as styles from './MonthlyPaymentListItem.module.css';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';

const MonthlyPaymentList = ({month}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.cell}>
                <span className={styles.label}>№ месяца</span>
                <div className={styles.value}>{month.monthNum}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Возмещение контрактной стоимости, c НДС</span>
                <div className={styles.value}>{formatNumberWithSpaces(month.repayment)}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Лизинговое вознаграждение</span>
                <div className={styles.value}>{formatNumberWithSpaces(month.leasingFee)}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>НДС на ЛВ</span>
                <div className={styles.value}>{formatNumberWithSpaces(month.VAT) || '-'}</div>
            </div>
            <div className={styles.cell}>
                <span className={styles.label}>Сумма лизингового платежа с НДС</span>
                <div className={styles.value}><b>{formatNumberWithSpaces(month.payment)}</b></div>
            </div>
        </div>
    );
};

export default MonthlyPaymentList;