import React from 'react';
import styles from './Separator.module.css';

const Separator = ({children, single}) => {
    return (
        <div className={`${styles.separator} ${single? styles.single: ''}`}>
            <div className={styles.line}/>
            {children}
            <div className={styles.line}/>
        </div>
    );
};

export default Separator;