import React, {useState, useContext} from 'react';
import styles from './LoginForm.module.css';
import FormInput from './FormInput';
import ArrowButton from '../common/ArrowButton';
import {authenticate, requestSalt} from '../../api/User';
import LoadingIndicator from '../common/LoadingIndicator';
import { useHistory, Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import {preHashPassword} from '../../utils/AuthUtils';

const LoginForm = () => {
    const {user, saveUser} = useContext(UserContext);
    const routerHistory = useHistory();
    const [loading, setLoading] = useState(false);
    const formStatusTypes = {NONE: 0, SUCCESS: 1, FAIL: 2};
    const [formStatus, setFormStatus] = useState(formStatusTypes.NONE);
    const [lastSubmitError, setLastSubmitError] = useState('');

    const initialFormInputs = {
        email: {value: '', valid: false, touched: false, placeholder: 'Адрес электронной почты', maxLength: 128},
        password: {value: '', valid: false, touched: false, placeholder: 'Пароль', maxLength: 128, type: 'password'},
    };
    const [formInputs, setFormInputs] = useState(initialFormInputs);

    const validators = {
        password: (value) => {
            return value.length >= 6;
        },
        email: (value) => {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value);
        }
    };

    let handleInputChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        const isValid = validators[name] ? validators[name](value) : true;
        setFormInputs({...formInputs, [name]: {...formInputs[name], value: value, valid: isValid, touched: true}});
        setFormStatus(formStatusTypes.NONE);
    };

    let markInvalidFieldsAsTouched = () => {
        let formDataCopy = {...formInputs};
        for (let [key, value] of Object.entries(formDataCopy)){
            if(!value.valid)
                value.touched = true;
        }
        setFormInputs(formDataCopy);
    };

    let handleSubmit = async (event) => {
        event.preventDefault();
        const allFieldsValid = Object.keys(formInputs).every(key => formInputs[key].valid === true);
        if (allFieldsValid){
            const dataToSubmit = {};
            for (let key in formInputs){
                if(formInputs.hasOwnProperty(key)){
                    dataToSubmit[key] = formInputs[key].value;
                }
            }
           try{
               setLoading(true);
               const requestedSalt = await requestSalt(dataToSubmit['email']);
               dataToSubmit['password'] = await preHashPassword(dataToSubmit['password'], requestedSalt);
               authenticate(dataToSubmit)
                   .then(processSuccessfulSubmit)
                   .catch(processFailedSubmit);
           }catch (e){
               processFailedSubmit(e);
           }
        }else{
            markInvalidFieldsAsTouched();
        }
    };

    let processSuccessfulSubmit = (userData) => {
        setLoading(false);
        if(userData?.accessToken){
            setFormInputs(initialFormInputs);
            setFormStatus(formStatusTypes.SUCCESS);

            saveUser(userData);
            routerHistory.push('/');
        }
    };

    let processFailedSubmit = (error) => {
        setFormStatus(formStatusTypes.FAIL);
        setLastSubmitError(error.message);
        setLoading(false);
        if (error instanceof TypeError) {
            alert('Проверьте подключение к интернету.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            <h2 className={styles.formTitle}>Личный кабинет</h2>
            <h3 className={styles.formSubtitle}>Вход в учетную запись</h3>
            {Object.entries(formInputs).map(([key, field]) => {
                return (
                    <FormInput key={key} name={key} placeholder={field.placeholder} type={field.type? field.type: 'text'}
                               value={field.value} valid={field.valid} touched={field.touched}
                               onChange={handleInputChange} maxLength={field.maxLength}/>
                );
            })}
            {formStatus === formStatusTypes.FAIL ? <div className={styles.errorContainer}>{lastSubmitError}</div> : ''}
            <div className={styles.buttonsContainer}>
                <Link to="/register" style={{textDecoration: 'none'}}>
                    <ArrowButton filled={false} type="button">Нет аккаунта?</ArrowButton>
                </Link>
                <ArrowButton type="submit">Войти</ArrowButton>
            </div>
            <LoadingIndicator shown={loading} style={{top: '-5px', left: '-5px', right: '-5px', bottom: '-5px'}}/>
        </form>
    );
};

export default LoginForm;