import React, {useContext} from 'react';
import * as styles from './AppHeaderProfile.module.css';
import DropdownList from '../common/DropdownList';
import {Link} from 'react-router-dom';
import UserContext from '../../context/UserContext';
import {ReactComponent as UserIcon} from '../../assets/images/user-icon.svg';

const AppHeaderProfile = ({onLinkClick}) => {
    const {user} = useContext(UserContext);
    return (
        <div className={styles.wrapper}>
            <div className={styles.spacer}/>
            <DropdownList className={styles.dropdown} label={<div className={styles.label}><UserIcon/>{user.name ? `${user.name} ${user.surname}` : 'Пользователь'}</div>}>
                <Link className={styles.link} to='/profile' onClick={onLinkClick}>Профиль</Link>
                <Link className={styles.link} to='/sessions' onClick={onLinkClick}>Сессии</Link>
                <Link className={styles.link} to='/logout' onClick={onLinkClick}>Выход</Link>
            </DropdownList>
        </div>
    );
};

export default AppHeaderProfile;