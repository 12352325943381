import React from 'react';
import * as styles from './FileDropInputItem.module.css';
import {ReactComponent as FileIcon} from '../../assets/images/file-icon.svg';
import {ReactComponent as BinIcon} from '../../assets/images/bin-icon.svg';

const FileDropInputItem = ({file, onRemoveClick}) => {
    let fileSize = (file.size / 1024);
    fileSize = fileSize > 1024 ? `${(fileSize / 1024).toFixed(2)} МБ` : `${fileSize.toFixed(2)} КБ`;
    return (
        <div className={styles.wrapper}>
            <div className={styles.fileIconWrapper}><FileIcon/></div>
            <div className={styles.name} title={file.path}>{file.path}</div>
            <div className={styles.size}>{fileSize}</div>
            <button className={styles.removeButton} onClick={()=>onRemoveClick(file)}><BinIcon/><div className={styles.buttonText}>Удалить</div></button>
        </div>
    );
};

export default FileDropInputItem;