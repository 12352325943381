import React from 'react';
import styles from './TextHeader.module.css';
import Separator from './Separator';

const TextHeader = ({children}) => {
    return (
        <div className={styles.wrapper} >
            <Separator single={true}><h2 className={styles.text}>{children}</h2></Separator>
        </div>
    );
};

export default TextHeader;