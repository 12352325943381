import React from 'react';
import styles from './FormInput.module.css';
import {ReactComponent as CheckCircleIcon} from '../../assets/images/check-circle-icon.svg';
import {ReactComponent as CrossCircleIcon} from '../../assets/images/cross-circle-icon.svg';
import formatPhone from '../../utils/formatPhone';

const FormInput = ({value, type, placeholder, name, onChange, valid, touched, maxLength, className}) => {
    const maskPhoneInput = (event) => {
        event.target.value = formatPhone(event.target.value);
    };

    const maskNumber = (event) => {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
    };

    const maskFloat = (event) => {
        if(event.target.value !== null){
            event.target.value = event.target.value.replace(/[^\d.,]/g, '');
            event.target.value = event.target.value.replace(/,/g, '.');
            const fPointIndex = event.target.value.indexOf('.');
            if(fPointIndex !== -1){
                const intPart = event.target.value.slice(0, fPointIndex);
                let floatPart = event.target.value.slice(fPointIndex + 1, fPointIndex + 3);
                floatPart = floatPart.replace('.', '');
                event.target.value = `${intPart}.${floatPart}`;
            }
        }
    };

    const handleOnInput = (event) => {
        if(event.target.value !== null) {
            if (type === 'phone')
                return maskPhoneInput(event);
            if (type === 'number')
                return maskNumber(event);
            if (type === 'float')
                return maskFloat(event);
        }
    };

    const empty = !value && !value?.toString().length;

    return (
        <div className={`${styles.wrapper} ${empty ? styles.empty : ''}
                                           ${className ? className : ''}
                                           ${touched ? styles.touched : styles.untouched}
                                           ${valid ? styles.valid : styles.invalid}
                                           ${type === 'phone' ? styles.typePhone : ''}`}>
            {touched && !valid ? <CrossCircleIcon/> : <CheckCircleIcon/>}
            {type === 'phone' ? <span className={styles.countryCode}>+375</span> : ''}
            <span className={styles.placeholder}>{placeholder}</span>
            <input type={type !== 'number' && type !== 'float' ? type : 'text'} value={value !== null ? value : ''} name={name} onChange={onChange} maxLength={maxLength} onInput={handleOnInput} autoComplete='off'/>
        </div>
    );
};

export default FormInput;