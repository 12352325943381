import React, {useState} from 'react';
import LargeButton from '../common/LargeButton';
import WhiteBox from '../common/WhiteBox';
import * as styles from './ActionWithConfirmation.module.css';
import LoadingIndicator from './LoadingIndicator';
import FormInput from "../form/FormInput";

const ActionWithConfirmation = ({title, subtitle, actionButtonTitle, confirmationText, disabled, loading, loadingText, actionToExecute, withComment, commentLength}) => {
    const [step, setStep] = useState(0);
    const confirmationHandler = (comment) =>{
        setStep(0);
        actionToExecute(withComment ? comment : null);
    };
    let handleInputChange = (event) =>{
        const value = event.target.value;
        const isValid = commentInputValidator(value);
        setCommentInput({value: value, valid: isValid, touched: true});
    };
    const [commentInput, setCommentInput] = useState({value: '', valid: true, touched: false});
    const commentInputValidator = (value) => {
        console.log(commentLength)
        return value.length <= commentLength;
    };
    return (
        <div className={styles.wrapper}>
            <LoadingIndicator shown={loading} withText={loadingText} />
            <WhiteBox>
                <h4 className={styles.title}>{title}</h4>
                {step === 0 ?
                    <>
                        <p className={styles.text}>{subtitle}</p>
                        <LargeButton disabled={disabled} className={styles.firstStepButton} filled={false} onClick={() => setStep(1)}>{actionButtonTitle}</LargeButton>
                    </>
                    : ''}
                {step === 1 ?
                    <>
                        <p className={styles.text}>{confirmationText}</p>
                        {withComment?
                            <FormInput className={styles.commentInput} name='comment' placeholder='Текст комментария' type='text' value={commentInput.value}
                                       valid={commentInput.valid} touched={commentInput.touched}
                                       onChange={handleInputChange} maxLength={commentLength}/>
                            : ''
                        }
                        <div className={styles.confirmationButtonsContainer}>
                            <LargeButton className={styles.cancelButton} filled={false} onClick={() => setStep(0)}>Отмена</LargeButton>
                            <LargeButton onClick={withComment? () => commentInput.valid && confirmationHandler(commentInput.value) : confirmationHandler} disabled={disabled || loading} className={styles.confirmButton} filled={true}>Продолжить</LargeButton>
                        </div>
                    </>
                    : ''}
            </WhiteBox>
        </div>
    );
};

export default ActionWithConfirmation;