import React, {useContext, useState} from 'react';
import PageWrapper from '../components/common/PageWrapper';
import { useHistory, Link } from 'react-router-dom';
import UserContext from '../context/UserContext';
import ArrowButton from '../components/common/ArrowButton';
import * as styles from './LogoutPage.module.css';
import WhiteBox from '../components/common/WhiteBox';
import {ReactComponent as WolfImage} from '../assets/images/wolf.svg'
import {ReactComponent as LogoutIcon} from '../assets/images/logout-icon.svg'
import LoadingIndicator from '../components/common/LoadingIndicator';
import {signOut} from '../api/User';

const LogoutPage = () => {
    const {user, saveUser} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const routerHistory = useHistory();

    const logOut = () => {
        setLoading(true);
        signOut().catch(error => {}).finally(() => {
            saveUser(false, ()=>{
                setLoading(false, ()=>{
                    routerHistory.push('/login');
                });
            });

        });
    };
    return (
        <div>
            <PageWrapper>
                <div className={styles.container}>
                    <WhiteBox>
                        <div className={styles.wolfImageWrapper}>
                            <WolfImage/>
                        </div>
                        <div className={styles.logoutImageWrapper}>
                            <LogoutIcon/>
                        </div>
                        <h3 className={styles.text}>{user.name}, Вы нас покидаете?</h3>
                        <div className={styles.buttonsWrapper}>
                            <ArrowButton onClick={logOut}>Нам нужно отдохнуть друг от друга</ArrowButton>
                            <Link to="/">
                                <ArrowButton reversed={true} filled={false}>Нет, впустите обратно</ArrowButton>
                            </Link>
                        </div>
                        <LoadingIndicator shown={loading} />
                    </WhiteBox>
                </div>
            </PageWrapper>
        </div>
    );
};

export default LogoutPage;