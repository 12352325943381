import {getAuthHeader} from '../utils/AuthUtils';
import ApplicationError from '../utils/ApplicationError';

const APIRoot = process.env.REACT_APP_API_ROOT || '/api/';

export function authenticate(userData) {
    return fetch(`${APIRoot}/user/sign-in`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
    }).then(async response => {
        if (response.status !== 200)
            throw new ApplicationError(await response.json(), response.status);
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function register(userData) {
    return fetch(`${APIRoot}/user/sign-up`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
    }).then(async response => {
        if (response.status !== 200)
            throw new ApplicationError(await response.json(), response.status);
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function requestSalt(email) {
    return fetch(`${APIRoot}/user/csalt/${encodeURIComponent(email)}`, {
        method: 'GET',
    }).then(async response => {
        if (response.status !== 200)
            throw new ApplicationError(await response.json(), response.status);
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function getUser() {
    return fetch(`${APIRoot}/user/self`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader()
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function getSessions() {
    return fetch(`${APIRoot}/user/sessions`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader()
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function extendToken() {
    return fetch(`${APIRoot}/user/token`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(true)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function signOut() {
    return fetch(`${APIRoot}/user/sign-out`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(true)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function destroySession(sessionId) {
    return fetch(`${APIRoot}/user/sign-out-other/${sessionId}`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}

export function confirmEmail(token) {
    return fetch(`${APIRoot}/user/confirm-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: token})
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
    });
}

export function sendConfirmationEmail() {
    return fetch(`${APIRoot}/user/send-confirmation-email/`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false)
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
    });
}