import React from 'react';
import * as styles from './MainBanner.module.css';

const MainBanner = () => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.image} src={`${process.env.PUBLIC_URL}/banner.jpg`}/>
            <div className={styles.text}>Просто Быстро Удобно</div>
        </div>
    );
};

export default MainBanner;