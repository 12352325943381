import React from 'react';
import * as styles from './FileItem.module.css';
import {ReactComponent as FileIcon} from '../../assets/images/file-icon.svg';

const FileItem = ({name, link, className, isForDownload, nameLength}) => {
    const maxNameLength = nameLength ? nameLength : 24;
    const ext = link?.split('.').pop();
    return (
        <a className={`${styles.wrapper} ${className}`} href={link} referrerPolicy='no-referrer' download={isForDownload}>
            <FileIcon/>
            <span className={styles.ext}>{ext}</span>
            <span className={styles.name}>{name.length <= maxNameLength ? name : `${name.slice(0, maxNameLength)}...`}</span>
        </a>
    );
};

export default FileItem;