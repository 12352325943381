import React, {useContext} from 'react';
import * as styles from './MonthlyPaymentPreview.module.css';
import PaymentScheduleContext from '../../context/PaymentScheduleContext';
import CalculatorParamsContext from '../../context/CalculatorParamsContext';
import formatNumberWithSpaces from '../../utils/formatNumberWithSpaces';


const MonthlyPaymentPreview = ({}) => {
    const {paymentSchedule, loadingSchedule} = useContext(PaymentScheduleContext);
    const {calculatorParams} = useContext(CalculatorParamsContext);
    const prepayDistributionLimit = 40;
    const monthIndex = parseFloat(calculatorParams.prepayPercentage) > prepayDistributionLimit ? 1 : 0;

    return (
        <div className={styles.wrapper}>
            {paymentSchedule ?
                <>
                    <div className={styles.payment}>
                        {formatNumberWithSpaces(paymentSchedule.months[monthIndex].payment)} {calculatorParams.currency}
                    </div>
                    <div className={styles.behavior}>
                        {calculatorParams.algo === 'annuity' ? 'не меняется с течением времени' : ''}
                        {calculatorParams.algo === 'differential' ? 'уменьшается с течением времени' : ''}
                    </div>
                </> :
                <>
                    {!loadingSchedule ?
                        <div className={styles.instructions}>
                            Для расчёта платежа укажите срок, стоимость и аванс
                        </div>
                        : <div className={styles.instructions}>
                            Расчёт...
                        </div>
                    }
                </>

            }
        </div>
    );
};

export default MonthlyPaymentPreview;