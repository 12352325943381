import React, {useContext, useState} from 'react';
import ActionWithConfirmation from '../common/ActionWithConfirmation';
import {sendConfirmationEmail} from '../../api/User';
import {isTokenIssue} from '../../utils/ApiUtils';
import UserContext from '../../context/UserContext';

const EmailConfirmationBlock = () => {
    const {tryRefreshTokensAndRepeatAction} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [confirmationSent, setConfirmationSent] = useState(false);

    const sendConfirmation = () => {
        setLoading(true);
        setErrorMessage(false);
        return sendConfirmationEmail().then(() => {
            setLoading(false);
            setConfirmationSent(true);
        }).catch(error =>{
            setLoading(false);
            if (isTokenIssue(error.message))
                throw error;
            else{
                setErrorMessage(error.message);
            }
        });
    };

    const wrappedSendConfirmation = async () =>{
        try{
            await sendConfirmation();
        }catch (error) {
            setLoading(true);
            await tryRefreshTokensAndRepeatAction(error.message, async ()=>sendConfirmation());
        }
    };

    return (
        <div>
            {!confirmationSent ? <ActionWithConfirmation title='Подтвердить адрес электронной почты'
                                    subtitle={'На указанный email-адрес было отправлено письмо. Воспользуйтесь этой функцией, если Вы не можете его найти'}
                                    confirmationText='На указанный email-адрес будет отправлено письмо с данными для подтверждения. Продолжить?'
                                    actionButtonTitle='Подтвердить адрес'
                                    disabled={loading}
                                    loading={loading}
                                    loadingText={'Отправка письма...'}
                                    actionToExecute={wrappedSendConfirmation}
            />
                :
                <div style={{color: 'var(--color-darkblue)', fontSize: '16px', fontWeight: 600}}>Письмо с подтверждением отправлено.
                    Может пройти некоторое время, прежде чем письмо отобразится в списке входящих. Также, если Вы не можете найти письмо, рекомендуется проверить вкладку «Спам»</div>
            }
            <div style={{color: 'var(--color-red)', fontSize: '12px'}}>{errorMessage}</div>
        </div>
    );
};

export default EmailConfirmationBlock;