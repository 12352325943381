import {getAuthHeader} from '../utils/AuthUtils';
import ApplicationError from '../utils/ApplicationError';
const APIRoot = process.env.REACT_APP_API_ROOT || '/api/';

export function getPaymentSchedule(calculationParams) {
    return fetch(`${APIRoot}/calculator/payment-schedule`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(calculationParams)
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}
export function getCalculatorConstraints() {
    return fetch(`${APIRoot}/calculator/constraints`, {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Authorization': getAuthHeader(false),
            'Content-Type': 'application/json',
        }
    }).then(async response => {
        if (response.status !== 200){
            throw new ApplicationError(await response.json(), response.status);
        }
        try{
            return await response.json();
        }catch (e) {
            throw e;
        }
    });
}
