import React from 'react';
import styles from './AuthFormWrapper.module.css';
import {ReactComponent as PuddleImage} from '../../assets/images/puddle.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg'

const AuthFormWrapper = ({children}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.topRightImageWrapper}>
                <div className={styles.largeSingleCircle}></div>
            </div>
            <div className={styles.bottomLeftImageWrapper}>
                <PuddleImage/>
            </div>
            <div className={styles.topLeftImageWrapper}>
                <div className={styles.logoWrapper}>
                    <Logo/>
                </div>
            </div>
            <div className={styles.bottomRightImageWrapper}>
                <div className={styles.circlePairWrapper}>
                    <div className={styles.largePairedCircle}></div>
                    <div className={styles.smallPairedCircle}></div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default AuthFormWrapper;